import { Flex } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { PageNotFoundStyle } from "../../assets/css/404";

const NotFoundPage = () => {
  return (
    <Flex style={PageNotFoundStyle.pageWrapper} className="page-wrapper">
      <Flex
        className="page-found-wrapper"
        style={PageNotFoundStyle.pageFoundWrapper}
      >
        <Flex className="page-found-heading">
          <h2 style={PageNotFoundStyle.pageFoundHeading}>
            4<span style={PageNotFoundStyle.headingZero}>0</span>4{" "}
          </h2>
        </Flex>
        <h2 style={PageNotFoundStyle.pageNotFound}>
          Page <span style={PageNotFoundStyle.pageNotFoundHighlight}>Not</span>
          &nbsp;Found
        </h2>
        <p style={PageNotFoundStyle.pageNotFoundText}>
          Sorry, we couldn't find the page you are looking for...
        </p>
        <Link to="/" style={PageNotFoundStyle.backToHomeLink}>
          Back to Home
        </Link>
      </Flex>
    </Flex>
  );
};

export default NotFoundPage;
