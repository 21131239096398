/**
 * Sets up the Redux store with a custom reducer and middleware configuration.
 *
 * @module store
 */

import { configureStore } from "@reduxjs/toolkit";
import { baseAPI } from "../services/baseApi";

/**
 * Creates the Redux store instance.
 *
 * @type {store}
 */
const store = configureStore({
  /**
   * Defines the reducer for the store.
   *
   * @type {Object}
   */
  reducer: {
    /**
     * Maps the base API reducer path to the base API reducer function.
     */
    [baseAPI.reducerPath]: baseAPI.reducer,
  },
  /**
   * Defines the middleware for the store.
   *
   * @param {Function} getDefaultMiddleware - Returns the default middleware.
   * @returns {Array} An array of middleware functions.
   */
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      /**
       * Disables the serializable check middleware.
       */
      serializableCheck: false,
    }).concat(baseAPI.middleware),
});

/**
 * Exports the Redux store instance as the default export.
 */
export default store;
