import { useAuth } from "react-oidc-context";
import { Navigate, Route, Routes } from "react-router-dom";
import Dashboard from "../pages/Dashboard";
import Settings from "../pages/Settings";
import NotFoundPage from "../pages/404";

// ProtectedRoute component
// Ensures that only authenticated users can access protected routes.
// If the user is not authenticated, they are redirected to the login page.
const ProtectedRoute = ({ children }) => {
  const auth = useAuth();

  if (!auth.isAuthenticated) {
    localStorage.removeItem("access_token", auth.user?.access_token);
    auth.signinRedirect();
    return null; // Redirect to login if not authenticated
  }

  return children; // Render children (protected content) if authenticated
};

// AppRoutes component
// Defines all the routes in the application, including protected and public routes.
// Uses ProtectedRoute for authenticated pages like the dashboard and CompareProperty.
const AppRoutes = () => {
  return (
    <Routes>
      <Route
        path="/dashboard"
        element={
          <ProtectedRoute>
            <Dashboard />
          </ProtectedRoute>
        }
      />
      <Route
        path="/settings"
        element={
          <ProtectedRoute>
            <Settings />
          </ProtectedRoute>
        }
      />

      <Route
        path="/"
        element={
          <ProtectedRoute>
            <Navigate to="/dashboard" />
          </ProtectedRoute>
        }
      />

      {/* Wildcard route for undefined pages */}
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default AppRoutes;
