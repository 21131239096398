import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Checkbox,
  Col,
  Collapse,
  Flex,
  InputNumber,
  Row,
  Slider,
  Typography,
} from "antd";
import React, { useState } from "react";
import { FilterDropdownStyle } from "../../../assets/css/Home";
import { FilterationIcon } from "../../../assets/images/Icons";

export const filterData = [
  {
    type: "checkbox",
    key: "type",
    options: [
      { value: "Buy", label: "Buy" },
      { value: "Rent", label: "Rent" },
      { value: "Sell", label: "Sell" },
    ],
  },
  {
    type: "checkbox",
    title: "Property Type",
    key: "propertyType",
    options: [
      { value: "allType", label: "All Type" },
      { value: "House", label: "House" },
      { value: "Villa", label: "Villa" },
      { value: "Land", label: "Land" },
      { value: "Rural", label: "Rural" },
      { value: "Acreage", label: "Acreage" },
      { value: "Apartment & Unit", label: "Apartment & Unit" },
      { value: "Townhouse", label: "Townhouse" },
      { value: "Block of Units", label: "Block of Units" },
    ],
  },
  {
    type: "priceRange",
    title: "Price Range",
    key: "priceRange",
  },
  {
    type: "counter",
    title: "Rooms",
    key: "rooms",
    options: [
      { value: "Bedroom", label: "Bedroom" },
      { value: "Bathrooms", label: "Bathrooms" },
    ],
  },
  {
    type: "checkbox",
    title: "Portions",
    key: "portions",
    options: [
      { value: "Single", label: "Single" },
      { value: "Double", label: "Double" },
      { value: "Triple", label: "Triple" },
    ],
  },
  {
    type: "checkbox",
    title: "Car Parking",
    key: "carParking",
    options: [
      { value: "Yes", label: "Yes" },
      { value: "No", label: "No" },
    ],
  },
  {
    type: "checkbox",
    title: "Outdoor Features",
    key: "outdoorFeatures",
    options: [
      { value: "Shed", label: "Shed" },
      { value: "Garage", label: "Garage" },
      { value: "Balcony", label: "Balcony" },
      { value: "Swimming Pool", label: "Swimming Pool" },
      { value: "Outdoor Area", label: "Outdoor Area" },
      { value: "Undercover Parking", label: "Undercover Parking" },
      { value: "Outdoor Spa", label: "Outdoor Spa" },
      { value: "Tennis Court", label: "Tennis Court" },
      { value: "Full Fenced", label: "Full Fenced" },
    ],
  },
];

/**
 * FilterDropdown component that generates the filter UI elements such as checkboxes, price range sliders,
 * and counters for filtering data. It dynamically renders different filter types based on the filterData input.
 *
 * @param {Array} filterCheckedList - The current state of selected filters, such as selected checkboxes and ranges.
 * @param {Function} setFilterCheckedList - Function to update the selected filters.
 * @param {Array} filterData - Array of filter configurations, where each filter object contains:
 *   - type: string - The type of filter (e.g., 'checkbox', 'priceRange', 'counter').
 *   - title?: string - The title of the filter section.
 *   - key: string - The unique key for the filter.
 *   - options?: Array - Array of options for checkbox or counter filters.
 *
 * @returns {Array} Returns the structured dropdown menu items for the filtering UI.
 */
export const FilterDropdown = (
  filterCheckedList,
  setFilterCheckedList,
  filterData
) => {
  const [rangeValues, setRangeValues] = useState([20, 50000]);
  const [counts, setCounts] = useState({
    Bedroom: 0,
    Bathrooms: 0,
  });

  /**
   * Handles the slider change for the price range filter and updates the filterCheckedList.
   * @param {Array} newValue - The updated values from the price range slider.
   */
  const onSliderChange = (newValue) => {
    setRangeValues(newValue);
    setFilterCheckedList({
      ...filterCheckedList,
      priceRange: {
        min: newValue[0],
        max: newValue[1],
      },
    });
  };

  /**
   * Handles the change for the minimum price input and updates the range values and filterCheckedList.
   * @param {number} value - The new minimum price value entered by the user.
   */
  const onFromChange = (value) => {
    setRangeValues([value, rangeValues[1]]);
    setFilterCheckedList({
      ...filterCheckedList,
      priceRange: {
        min: value,
        max: rangeValues[1],
      },
    });
  };

  /**
   * Handles the change for the maximum price input and updates the range values and filterCheckedList.
   * @param {number} value - The new maximum price value entered by the user.
   */
  const onToChange = (value) => {
    setRangeValues([rangeValues[0], value]);
    setFilterCheckedList({
      ...filterCheckedList,
      priceRange: {
        min: rangeValues[0],
        max: value,
      },
    });
  };

  /**
   * Increments the counter for a specific room type (Bedroom or Bathrooms) and updates the filterCheckedList.
   * @param {string} field - The field name (either 'Bedroom' or 'Bathrooms') to increment the count.
   */
  const incrementCount = (field) => {
    const newCount = counts[field] + 1;
    setCounts({
      ...counts,
      [field]: newCount,
    });

    setFilterCheckedList({
      ...filterCheckedList,
      rooms: {
        ...filterCheckedList.rooms,
        [field]: newCount,
      },
    });
  };

  /**
   * Decrements the counter for a specific room type (Bedroom or Bathrooms) and updates the filterCheckedList.
   *
   * @param {string} field - The field name (either 'Bedroom' or 'Bathrooms') to decrement the count.
   */
  const decrementCount = (field) => {
    const newCount = counts[field] > 0 ? counts[field] - 1 : 0;
    setCounts({
      ...counts,
      [field]: newCount,
    });

    setFilterCheckedList({
      ...filterCheckedList,
      rooms: {
        ...filterCheckedList.rooms,
        [field]: newCount,
      },
    });
  };

  /**
   * Dynamically renders different filter components (checkbox, price range, or counter) based on the filter type.
   *
   * @param {Object} filter - The filter configuration object containing the type, options, and key for the filter.
   * @returns {JSX.Element|null} The rendered filter component or null if the filter type is not recognized.
   */
  const renderFilterComponent = (filter) => {
    switch (filter.type) {
      case "checkbox":
        return (
          <Checkbox.Group
            options={filter.options.map((option) => ({
              label: (
                <Typography style={FilterDropdownStyle.LabelTypography}>
                  {option.label}
                </Typography>
              ),
              value: option.value,
            }))}
            value={filterCheckedList[filter.key] || []}
            onChange={(list) => {
              setFilterCheckedList({
                ...filterCheckedList,
                [filter.key]: list,
              });
            }}
          />
        );
      case "priceRange":
        return (
          <Flex style={{ width: "100%" }}>
            <Row style={FilterDropdownStyle.FlexColumn}>
              <Col style={FilterDropdownStyle.PriceRangeCol}>
                <Typography style={FilterDropdownStyle.PriceRangeTypography}>
                  $0
                </Typography>
                <Typography style={FilterDropdownStyle.PriceRangeTypography}>
                  $100000
                </Typography>
              </Col>
              <Col>
                <Slider
                  min={0}
                  max={100000}
                  range
                  value={rangeValues}
                  onChange={onSliderChange}
                />
              </Col>
              <Col style={FilterDropdownStyle.PriceInputWrapper}>
                <div style={FilterDropdownStyle.PriceInputContainer}>
                  <Typography style={FilterDropdownStyle.PriceInputTypography}>
                    From ($)
                  </Typography>
                  <InputNumber
                    min={0}
                    max={rangeValues[1]} // Set max of "From" as "To" value
                    style={{ width: "100%" }}
                    value={rangeValues[0]}
                    onChange={onFromChange}
                  />
                </div>
                <div style={FilterDropdownStyle.PriceInputContainer}>
                  <Typography style={FilterDropdownStyle.PriceInputTypography}>
                    To ($)
                  </Typography>
                  <InputNumber
                    min={rangeValues[0]}
                    max={100000}
                    style={{ width: "100%" }}
                    value={rangeValues[1]}
                    onChange={onToChange}
                  />
                </div>
              </Col>
            </Row>
          </Flex>
        );
      case "counter":
        return (
          <Flex style={{ flexDirection: "column", gap: "10px", width: "100%" }}>
            {filter.options.map((item) => (
              <Flex
                key={item.value}
                style={{ justifyContent: "space-between", width: "100%" }}
              >
                <Typography style={{ color: "#667085", fontSize: "17px" }}>
                  {item.label}
                </Typography>
                <Flex style={FilterDropdownStyle.CounterControl}>
                  <Typography
                    style={FilterDropdownStyle.CounterButton}
                    onClick={() => decrementCount(item.value)}
                  >
                    <MinusOutlined />
                  </Typography>
                  <Typography style={{ marginInline: "15px" }}>
                    {counts[item.value]}
                  </Typography>
                  <Typography
                    style={FilterDropdownStyle.CounterButton}
                    onClick={() => incrementCount(item.value)}
                  >
                    <PlusOutlined />
                  </Typography>
                </Flex>
              </Flex>
            ))}
          </Flex>
        );
      default:
        return null;
    }
  };

  // Rendering the overall dropdown structure
  return [
    {
      label: (
        <Flex style={FilterDropdownStyle.FlexFullWidth}>
          <Typography
            style={{ color: "#1F1F1F", fontWeight: 500, fontSize: "18px" }}
          >
            Filter
          </Typography>
          <FilterationIcon />
        </Flex>
      ),
      key: "0",
    },
    {
      type: "divider",
    },
    ...filterData.map((filter, index) => {
      const component = renderFilterComponent(filter);

      if (filter.title) {
        return {
          label: (
            <Flex
              style={FilterDropdownStyle.FlexFullWidth}
              onClick={(e) => e.stopPropagation()}
            >
              <Collapse
                defaultActiveKey={[`${index}`]}
                ghost
                expandIconPosition="end"
                style={{ width: "100%" }}
                items={[
                  {
                    key: index.toString(),
                    label: (
                      <Typography style={FilterDropdownStyle.TitleTypography}>
                        {filter.title}
                      </Typography>
                    ),
                    children: (
                      <div style={{ display: "flex" }}>{component}</div>
                    ),
                  },
                ]}
              />
            </Flex>
          ),
          key: (index + 1).toString(),
        };
      } else {
        return {
          label: (
            <Flex
              style={FilterDropdownStyle.FlexFullWidth}
              onClick={(e) => e.stopPropagation()}
            >
              <div>{component}</div>
            </Flex>
          ),
          key: (index + 1).toString(),
        };
      }
    }),
  ];
};
