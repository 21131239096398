import { Table } from "antd";
import React from "react";

/**
 * CustomizeTable component that renders a customizable table using Ant Design's Table component.
 * It accepts data, columns, and row selection as props and supports sticky headers and scroll options.
 *
 * @param {Array} dataSource - Array of data to be displayed in the table.
 * @param {Array} columns - Array of objects representing the columns of the table.
 * @param {object} rowSelection - Object containing row selection configuration for the table.
 *
 * @returns {JSX.Element} A table component with customizable columns, row selection, and scroll options.
 */
const CustomizeTable = (props) => {
  // Destructure props to access data, columns, and row selection
  const {
    dataSource,
    columns,
    rowSelection,
    showHeader = true,
    isLoading = false,
  } = props;
  return (
    <Table
      loading={isLoading}
      showHeader={showHeader}
      columns={columns} // Set the columns of the table
      dataSource={dataSource} // Set the data source for the table
      rowSelection={{
        ...rowSelection, // Apply row selection configuration
      }}
      size="small"
      scroll={{
        x: 1500, // Enable horizontal scrolling for wide tables
      }}
      sticky={{
        offsetHeader: 64, // Make the header sticky with an offset
      }}
    />
  );
};

export default CustomizeTable;

/**
 * @example
 * // Example usage of CustomizeTable in a parent component:
 *
 * import React, { useState } from "react";
 * import CustomizeTable from "./CustomizeTable";
 *
 * const SamplePage = () => {
 *   const dataSource = [
 *     { key: "1", name: "John Doe", age: 32, address: "1234 Elm St" },
 *     { key: "2", name: "Jane Smith", age: 28, address: "5678 Oak Ave" },
 *   ];
 *
 *   const columns = [
 *     { title: "Name", dataIndex: "name", key: "name" },
 *     { title: "Age", dataIndex: "age", key: "age" },
 *     { title: "Address", dataIndex: "address", key: "address" },
 *   ];
 *
 *   const [selectedRowKeys, setSelectedRowKeys] = useState([]);
 *
 *   const rowSelection = {
 *     selectedRowKeys,
 *     onChange: (newSelectedRowKeys) => {
 *       setSelectedRowKeys(newSelectedRowKeys);
 *     },
 *   };
 *
 *   return (
 *     <CustomizeTable
 *       dataSource={dataSource}  // Table data
 *       columns={columns}       // Column structure
 *       rowSelection={rowSelection}  // Row selection configuration
 *     />
 *   );
 * };
 *
 * export default SamplePage;
 *
 */
