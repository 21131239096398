/**
 * API setup component using @reduxjs/toolkit/query/react.
 *
 * This component defines a basic API configuration that can be used to make requests to a base URL.
 * It also includes functionality to automatically add an Authorization header with a Bearer token from local storage.
 */

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import environments from "../utils/environments";

/**
 * Base query configuration for API requests.
 *
 * @param {object} options - Options for the base query.
 * @param {string} options.baseUrl - Base URL for API requests.
 * @param {function} options.prepareHeaders - Function to prepare headers for API requests.
 */
const baseQuery = fetchBaseQuery({
  /**
   * Base URL for API requests.
   */
  baseUrl: environments.apiUrl,

  /**
   * Prepare headers for API requests.
   *
   * @param {Headers} headers - Headers for the API request.
   * @param {object} api - API object.
   * @param {function} api.getState - Function to get the current state.
   * @returns {Headers} Prepared headers.
   */
  prepareHeaders: (headers, { getState }) => {
    /**
     * Get access token from local storage.
     */
    const token = localStorage.getItem("access_token");
    if (token) {
      /**
       * Add Authorization header with Bearer token.
       */
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

/**
 * Base API configuration.
 *
 * @type {object}
 */
export const baseAPI = createApi({
  /**
   * Reducer path for the API.
   */
  reducerPath: "baseAPI",
  /**
   * Base query configuration.
   */
  baseQuery,
  /**
   * Endpoints for the API.
   *
   * @returns {object} Endpoints.
   */
  endpoints: () => ({}),
});
