import { ConfigProvider } from "antd";
import React from "react";
import ReactDOM from "react-dom/client";
import { AuthProvider } from "react-oidc-context";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { theme } from "./theme/colors";
import environments from "./utils/environments";
import "./assets/css/Global.css";
import store from "./store/store";
import { Provider } from "react-redux";

const oidcConfig = {
  authority: environments.authUrl + "/realms/" + environments.authRealm,
  client_id: environments.authClient,
  redirect_uri: window.location.href,
  response_type: "code",
  scope: "openid profile email",
  onSigninCallback: (_user) => {
    window.history.replaceState({}, document.title, window.location.pathname);
  },
};

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <ConfigProvider theme={theme}>
          <AuthProvider {...oidcConfig}>
            <App />
          </AuthProvider>
        </ConfigProvider>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);
