export const PageNotFoundStyle = {
  pageWrapper: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  pageFoundWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  pageFoundHeading: {
    margin: 0,
    color: "#264653",
  },
  headingZero: {
    color: "#1db3b3",
  },
  pageNotFound: {
    fontSize: "32px",
    margin: 0,
    textAlign: "center",
    color: "#264653",
  },
  pageNotFoundHighlight: {
    color: "#1db3b3",
  },
  pageNotFoundText: {
    fontSize: "16px",
    margin: 0,
    textAlign: "center",
    display: "block",
    color: "#264653",
  },
  backToHomeLink: {
    color: "#FFF",
    fontSize: "18px",
    fontWeight: "500",
    cursor: "pointer",
    background: "#1db3b3",
    padding: "10px 20px", // Added padding for better button look
    textDecoration: "none", // Avoid underline in links
  },
};
