export const AuthLayoutStyle = {
  LayoutRow: {
    height: "100%",
    width: "100%",
  },
  LeftCol: {
    backgroundColor: "#fff",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "2rem",
    overflowY: "scroll",
    height: "100%",
  },
  LeftColDiv: {
    width: "100%",
    maxWidth: "360px",
  },
  RightCol: {
    backgroundImage: `url('/AuthImage.svg')`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: "100vh",
  },
};
