import { Modal } from "antd";
import React from "react";

/**
 * A custom modal component that takes title, children, onOpen, onCancel, and
 * width as props. It renders a Modal component with the given props.
 *
 * @param {string} title - The title of the modal.
 * @param {React.ReactNode} children - The content of the modal.
 * @param {boolean} onOpen - Whether the modal is open.
 * @param {Function} onCancel - The function to call when the cancel button is clicked.
 * @param {number} width - The width of the modal.
 */
export const CustomModel = ({
  title = "",
  children,
  onOpen = false,
  onCancel,
  width = 600,
}) => {
  return (
    <Modal
      title={<span className="fs-24 title-color fw-600">{title}</span>}
      open={onOpen}
      onCancel={onCancel}
      width={width}
      footer={null}
    >
      {children}
    </Modal>
  );
};
