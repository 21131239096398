import { CloseOutlined, MenuOutlined } from "@ant-design/icons";
import { Button, Col, Drawer, Flex, Layout, Row, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AppLayoutStyle } from "../../assets/css/AppLayout";
import { HeaderLogo } from "../../assets/images/Icons";

// Destructure Layout into its main components
const { Header, Content, Footer } = Layout;

// Navigation menu items
const NAVBAR_DATA = [
  { title: "Home", redirectTo: "/" },
  { title: "Settings", redirectTo: "/settings" },
];
// Contact details for the "Contact Us" section
const CONTACT_US_DATA = [
  { label: "Email Address:", value: "mail@realvation.de" },
  { label: "Phone Number:", value: "+49 173 34 43 368" },
  {
    label: "Address:",
    value: "Berliner Str. 300b, 63065 Offenbach am Main",
  },
];

const FOOTER_DATA = [
  {
    label: "Menu", // Footer menu structure
    subData: ["Home", "About Us", "Services", "Contact Us"],
  },
  {
    label: "Company", // Company-related links in the footer
    subData: ["Terms & Condition", "Imprint", "Privacy Policy"],
  },
];

/**
 * Main layout component for the application. It includes a header with navigation,
 * footer, and responsive drawer for mobile view. The component also handles authentication states.
 *
 * @param {object} auth - Authentication object with methods to sign in and sign out.
 * @param {ReactNode} children - The content to be rendered within the layout.
 */
const AppLayout = ({ auth, children }) => {
  const navigate = useNavigate();
  // Tracks the current selected navigation item
  const [selectedItem, setSelectedItem] = useState("Home");

  // Manages the state of the mobile drawer
  const [openDrawer, setOpenDrawer] = useState(false);

  // Tracks if the layout is in mobile view
  const [isResponsive, setIsResponsive] = useState(false);

  /**
   * Handles the selected item in the navigation menu.
   * @param {string} value - The selected navigation item.
   */
  const handleSelectedItem = (value) => {
    setSelectedItem(value.title);
    navigate(value.redirectTo);
  };

  /**
   * Toggles the drawer visibility for mobile view.
   */
  const showDrawer = () => setOpenDrawer(!openDrawer);

  /**
   * Monitors screen size changes and adjusts layout for responsive design.
   * It listens for window width changes and sets the responsive state accordingly.
   */
  useEffect(() => {
    // Media query for screens smaller than 930px
    const mediaQuery = window.matchMedia("(max-width: 930px)");

    // Update isResponsive based on screen size
    const handleMediaQueryChange = (e) => setIsResponsive(e.matches);

    // Set initial state based on current screen size
    setIsResponsive(mediaQuery.matches);
    // Add listener for media query changes
    mediaQuery.addEventListener("change", handleMediaQueryChange);

    // Cleanup listener when component unmounts
    return () =>
      mediaQuery.removeEventListener("change", handleMediaQueryChange);
  }, []);

  return (
    <Layout style={{ height: "auto" }}>
      {/* Header section with logo, navigation menu, and auth buttons */}
      <Header style={AppLayoutStyle.HeaderMain}>
        <Row style={AppLayoutStyle.RowStyle(isResponsive)}>
          <Col span={4} style={AppLayoutStyle.ColLogoStyle}>
            {/* Logo section */}
            <Flex>
              <HeaderLogo />
            </Flex>
          </Col>

          {/* Navigation and Auth buttons for larger screens */}
          {!isResponsive && (
            <>
              <Col span={14} style={AppLayoutStyle.ColNavBarStyle}>
                {NAVBAR_DATA.map((page) => (
                  <Typography
                    key={page.title}
                    style={AppLayoutStyle.NavTypography(
                      selectedItem,
                      page.title
                    )} // Dynamic styles based on selected item
                    onClick={() => handleSelectedItem(page)} // Handle click on menu item
                  >
                    {page.title}
                  </Typography>
                ))}
              </Col>
              <Col span={6} style={AppLayoutStyle.ColAuthButtons}>
                <Button
                  type="primary"
                  size="large"
                  style={AppLayoutStyle.ButtonSignup}
                  onClick={() => {
                    auth.signoutRedirect();
                  }}
                >
                  Logout
                </Button>
              </Col>
            </>
          )}

          {/* Drawer for mobile screens */}
          {isResponsive && (
            <MenuOutlined
              style={AppLayoutStyle.MenuIconStyle} // Icon style for mobile menu
              onClick={showDrawer} // Toggle drawer on click
            />
          )}
        </Row>

        {/* Mobile Drawer */}
        <Drawer
          placement="left"
          closable={false}
          onClose={showDrawer} // Close drawer
          open={openDrawer} // Drawer visibility
          key="left"
          width="280px"
        >
          <Flex vertical style={AppLayoutStyle.DrawerFlexVertical}>
            <Flex
              style={{ alignItems: "center", justifyContent: "space-between" }}
            >
              {/* Drawer header with logo and close icon */}
              <HeaderLogo />
              <CloseOutlined
                style={AppLayoutStyle.DrawerCloseIcon}
                onClick={showDrawer} // Close drawer on click
              />
            </Flex>
            {/* Navigation items in the drawer */}
            <Flex vertical style={AppLayoutStyle.FlexNavColumn}>
              {NAVBAR_DATA.map((page) => (
                <Typography
                  key={page.title}
                  style={AppLayoutStyle.NavTypography(selectedItem, page.title)} // Dynamic styles based on selected item
                  onClick={() => handleSelectedItem(page)} // Handle click on drawer menu item
                >
                  {page.title}
                </Typography>
              ))}
              {/* Authentication buttons in the drawer */}
              <Flex style={AppLayoutStyle.FlexAuthButtonsColumn}>
                <Button
                  type="primary"
                  size="large"
                  style={AppLayoutStyle.ButtonSignup}
                  onClick={() => auth.signoutRedirect()}
                >
                  Logout
                </Button>
              </Flex>
            </Flex>
          </Flex>
        </Drawer>
      </Header>

      {/* Main content area where the child components are rendered */}
      <Content style={AppLayoutStyle.ContentStyle}>{children}</Content>

      {/* Footer section with company information, navigation, and contact details */}
      <Footer fixedPosition style={AppLayoutStyle.FooterMain}>
        <Row
          gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
          style={{ width: "100%" }}
        >
          <Col xs={24} sm={24} md={24} lg={6} style={AppLayoutStyle.FooterCol}>
            <HeaderLogo color="#FFFFFF" />
            <Typography style={{ color: "#FFFFFF", fontSize: "16px" }}>
              We are at the interface of real estate expertise and
              digitalization. We combine industry knowledge with modern
              technology solutions to successfully lead companies in the real
              estate industry into the digital future.
            </Typography>
          </Col>

          {FOOTER_DATA.map((item) => (
            <Col
              key={item.label}
              xs={24}
              sm={12}
              md={8}
              lg={6}
              style={AppLayoutStyle.FooterCol}
            >
              <Typography style={{ color: "#FFFFFF", fontSize: "18px" }}>
                {item.label}
              </Typography>
              {item.subData.map((subItem) => (
                <Typography
                  key={subItem}
                  style={{
                    color: "#FFFFFF",
                    fontSize: "14px",
                    fontWeight: "500",
                  }}
                >
                  {subItem}
                </Typography>
              ))}
            </Col>
          ))}

          <Col xs={24} sm={24} md={8} lg={6} style={AppLayoutStyle.FooterCol}>
            <Typography style={AppLayoutStyle.ContactUsTypography}>
              Contact Us
            </Typography>
            <Flex vertical gap="10px" style={{ width: "100%" }}>
              {CONTACT_US_DATA.map((item) => (
                <Flex
                  key={item.label}
                  vertical
                  gap="5px"
                  style={AppLayoutStyle.ContactCard}
                >
                  <Typography style={AppLayoutStyle.ContactLabel}>
                    {item.label}
                  </Typography>
                  <Typography style={AppLayoutStyle.ContactValue}>
                    {item.value}
                  </Typography>
                </Flex>
              ))}
            </Flex>
          </Col>
        </Row>
      </Footer>
    </Layout>
  );
};
export default AppLayout;
