import { RightOutlined } from "@ant-design/icons";
import { Checkbox, Flex, Popover, Typography } from "antd";
import React from "react";
import { HomePageStyles } from "../../../assets/css/Home";
import { CustomizeIcon } from "../../../assets/images/Icons";

/**
 * CustomizeDropdown function to generate the menu items for customizing the table.
 * Users can customize the table columns, select or deselect options, and manage the table structure.
 *
 * @param {Array} checkedList - List of checked column keys.
 * @param {Function} setCheckedList - Function to update the checked column keys.
 * @param {Array} columnOption - Array containing options for the table columns.
 * @returns {Array} Dropdown menu items for customizing the table.
 */
export const CustomizeDropdown = (
  checkedList,
  setCheckedList,
  columnOption
) => {
  return [
    {
      label: (
        <Typography style={HomePageStyles.CustomizeTableText}>
          Customize Table
        </Typography>
      ),
      key: "0",
    },
    {
      type: "divider",
    },
    {
      label: (
        <Popover
          placement="rightTop"
          content={
            <>
              <div
                className="column-checkbox"
                onClick={(e) => e.stopPropagation()}
              >
                <Checkbox.Group
                  options={columnOption.map((option) => ({
                    label: (
                      <Typography
                        style={{
                          color: "#1F1F1F",
                          fontSize: "16px",
                        }}
                      >
                        {option.label}
                      </Typography>
                    ),
                    value: option.value,
                  }))}
                  value={checkedList}
                  onChange={(list) => {
                    setCheckedList(list);
                  }}
                />
              </div>
            </>
          }
          arrow={false}
        >
          <Flex style={HomePageStyles.FlexFullWidth}>
            <Typography style={HomePageStyles.ColumnText}>Lines</Typography>
            <RightOutlined style={HomePageStyles.RightIcon} />
          </Flex>
        </Popover>
      ),
      key: "1",
      icon: <CustomizeIcon color="#667085" size={14} />,
    },
  ];
};
