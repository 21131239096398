import {
  ExclamationCircleFilled,
  UnorderedListOutlined,
} from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Dropdown,
  Flex,
  Form,
  Grid,
  message,
  Modal,
  Space,
  Tooltip,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { useAuth } from "react-oidc-context";
import { HomePageStyles } from "../../assets/css/Home";
import {
  CustomizeIcon,
  DownloadIcon,
  EditIcon,
  FilterIcon,
  GridIcon,
  TableActionIcon,
  TableIcon,
  TrashIcon,
} from "../../assets/images/Icons";
import {
  useDeleteListingMutation,
  useGetAllListingQuery,
  useGetUserSettingsQuery,
  useUpdateListingMutation,
} from "../../services/Listing";
import CustomizeTable from "../Common/CustomizeTable/CustomizeTable";
import { CustomizeDropdown } from "../Dashboard/CustomizeDropdown/CustomizeDropdown";
import { EditModel } from "../Dashboard/EditModel/EditModel";
import {
  filterData,
  FilterDropdown,
} from "../Dashboard/FilterDropdown/FilterDropdown";
import { CustomModel } from "../Common/CustomModel/CustomModel";
import { ComparePropertyComponent } from "../CompareProperty/CompareProperty";

const { useBreakpoint } = Grid;

// Different view options for the property listings (Grid, List, Table)
const CURRENT_LISTING_VIEW = [
  { id: 1, label: "Grid", icon: <GridIcon /> },
  { id: 2, label: "List", icon: <UnorderedListOutlined size={24} /> },
  { id: 3, label: "Table", icon: <TableIcon /> },
];

// Available actions for each item in the table, such as Edit, Delete, Download, and Filter
const TABLE_ACTIONS_GRID = [
  {
    id: 2,
    label: "Edit",
    bgColor: "#F2F4F7",
    color: "#1F1F1F",
    border: "none",
    icon: <EditIcon />,
  },
  {
    id: 3,
    label: "Delete",
    bgColor: "#FEF3F2",
    color: "#F04438",
    border: "none",
    icon: <TrashIcon />,
  },
  {
    id: 4,
    label: "Download",
    bgColor: "#DCFAE6",
    color: "#17B26A",
    border: "none",
    icon: <DownloadIcon />,
  },
  {
    id: 5,
    label: "Filter",
    bgColor: "#FFFFFF",
    color: "#344054",
    border: "1px solid #D0D5DD",
    icon: <FilterIcon />,
  },
  {
    id: 6,
    label: "Compare Listing",
    bgColor: "#1DB3B3",
    color: "#FFFFFF",
    border: "none",
    icon: null,
  },
];

// Table columns definition with each field for property information (e.g., ID, image, source)
export const TABLE_COLUMN = (handleTableAction) => {
  return [
    {
      title: "ID",
      dataIndex: "ListingID",
      key: "ListingID",
      fixed: "left",
      width: 90,
      ellipsis: {
        showTitle: false,
      },
      render: (text) => (text ? text : "N/A"),
    },
    {
      title: "Image",
      dataIndex: "ThumbnailURL", // Ändere dies auf den Schlüssel in deinen Daten, der die Thumbnail-URL enthält
      key: "ThumbnailURL",
      width: 80,
      ellipsis: {
        showTitle: false,
      },
      render: (src, record) => {
        if (!src) {
          return "N/A"; // Fallback, wenn kein Thumbnail verfügbar ist
        }
    
        return (
          <img
            src={src}
            alt={`Thumbnail of ${record.PropertyType || "property"}`}
            width={61}
            height={33}
            style={{ borderRadius: "4px", objectFit: "cover" }}
          />
        );
      },
    },
    {
      title: "Source",
      dataIndex: "Source",
      key: "Source",
      width: 90,
      ellipsis: {
        showTitle: false,
      },
      render: (text) => (text ? text : "N/A"),
    },
    {
      title: "Property Type",
      dataIndex: "PropertyType",
      key: "PropertyType",
      width: 130,
      ellipsis: {
        showTitle: false,
      },
      render: (text) => (text ? text : "N/A"),
    },
    {
      title: "Street",
      dataIndex: "Street",
      key: "Street",
      width: 100,
      ellipsis: {
        showTitle: false,
      },
      render: (text) => (text ? text : "N/A"),
    },
    {
      title: "House Number",
      dataIndex: "HouseNumber",
      key: "HouseNumber",
      width: 150,
      ellipsis: {
        showTitle: false,
      },
      render: (text) => (text ? text : "N/A"),
    },
    {
      title: "Postal Code",
      dataIndex: "PostalCode",
      key: "PostalCode",
      width: 120,
      ellipsis: {
        showTitle: false,
      },
      render: (text) => (text ? text : "N/A"),
    },
    {
      title: "City",
      dataIndex: "City",
      key: "City",
      width: 100,
      ellipsis: {
        showTitle: false,
      },
      render: (text) => (text ? text : "N/A"),
    },
    {
      title: "District",
      dataIndex: "District",
      key: "District",
      width: 100,
      ellipsis: {
        showTitle: false,
      },
      render: (text) => (text ? text : "N/A"),
    },
    {
      title: "State",
      dataIndex: "State",
      key: "State",
      width: 100,
      ellipsis: {
        showTitle: false,
      },
      render: (text) => (text ? text : "N/A"),
    },
    {
      title: "Purchase Price",
      dataIndex: "PurchasePrice",
      key: "PurchasePrice",
      width: 140,
      ellipsis: {
        showTitle: false,
      },
      render: (text) => (text ? `${text} €` : "N/A"),
    },
    {
      title: "Price",
      dataIndex: "PricePerSquareMeter",
      key: "PricePerSquareMeter",
      width: 80,
      ellipsis: {
        showTitle: false,
      },
      render: (text) => (text ? `${text} €` : "N/A"),
    },
    {
      title: "Rooms",
      dataIndex: "Rooms",
      key: "Rooms",
      width: 80,
      ellipsis: {
        showTitle: false,
      },
      render: (text) => (text ? text : "N/A"),
    },
    {
      title: "Area (m²)",
      dataIndex: "Area",
      key: "Area",
      width: 100,
      ellipsis: {
        showTitle: false,
      },
      render: (text) => (text ? `${text} m²` : "N/A"),
    },
    {
      title: "Usable Area (m²)",
      dataIndex: "UsableArea",
      key: "UsableArea",
      width: 150,
      ellipsis: {
        showTitle: false,
      },
      render: (text) => (text ? `${text} m²` : "N/A"),
    },
    {
      title: "Land Area (m²)",
      dataIndex: "LandArea",
      key: "LandArea",
      width: 140,
      ellipsis: {
        showTitle: false,
      },
      render: (text) => (text ? `${text} m²` : "N/A"),
    },
    {
      title: "Building Type",
      dataIndex: "BuildingType",
      key: "BuildingType",
      width: 130,
      ellipsis: {
        showTitle: false,
      },
      render: (text) => (text ? text : "N/A"),
    },
    {
      title: "Floors",
      dataIndex: "NumberOfFloors",
      key: "NumberOfFloors",
      width: 80,
      ellipsis: {
        showTitle: false,
      },
      render: (text) => (text ? text : "N/A"),
    },
    {
      title: "Available From",
      dataIndex: "AvailableFrom",
      key: "AvailableFrom",
      width: 140,
      ellipsis: {
        showTitle: false,
      },
      render: (text) => (text ? text : "N/A"),
    },
    {
      title: "Bedrooms",
      dataIndex: "Bedrooms",
      key: "Bedrooms",
      width: 120,
      ellipsis: {
        showTitle: false,
      },
      render: (text) => (text ? text : "N/A"),
    },
    {
      title: "Garage/Parking",
      dataIndex: "GarageOrParking",
      key: "GarageOrParking",
      width: 140,
      ellipsis: {
        showTitle: false,
      },
      render: (text) => (text ? text : "N/A"),
    },
    {
      title: "Buyer Commission",
      dataIndex: "BuyerCommission",
      key: "BuyerCommission",
      width: 160,
      ellipsis: {
        showTitle: false,
      },
      render: (text) => (text ? text : "N/A"),
    },
    {
      title: "Year of Construction",
      dataIndex: "YearOfConstruction",
      key: "YearOfConstruction",
      width: 170,
      ellipsis: {
        showTitle: false,
      },
      render: (text) => (text ? text : "N/A"),
    },
    {
      title: "Construction Phase",
      dataIndex: "ConstructionPhase",
      key: "ConstructionPhase",
      width: 170,
      ellipsis: {
        showTitle: false,
      },
      render: (text) => (text ? text : "N/A"),
    },
    {
      title: "Renovation",
      dataIndex: "Renovation",
      key: "Renovation",
      width: 110,
      ellipsis: {
        showTitle: false,
      },
      render: (text) => (text ? text : "N/A"),
    },
    {
      title: "Property Condition",
      dataIndex: "PropertyCondition",
      key: "PropertyCondition",
      width: 160,
      ellipsis: {
        showTitle: false,
      },
      render: (text) => (text ? text : "N/A"),
    },
    {
      title: "Quality of Equipment",
      dataIndex: "QualityOfEquipment",
      key: "QualityOfEquipment",
      width: 180,
      ellipsis: {
        showTitle: false,
      },
      render: (text) => (text ? text : "N/A"),
    },
    {
      title: "Hausgeld",
      dataIndex: "Hausgeld",
      key: "Hausgeld",
      width: 120,
      ellipsis: {
        showTitle: false,
      },
      render: (text) => (text ? text : "N/A"),
    },
    {
      title: "Main Energy Source",
      dataIndex: "MainEnergySource",
      key: "MainEnergySource",
      width: 170,
      ellipsis: {
        showTitle: false,
      },
      render: (text) => (text ? text : "N/A"),
    },
    {
      title: "Energy Certification",
      dataIndex: "EnergyCertificate",
      key: "EnergyCertificate",
      width: 170,
      ellipsis: {
        showTitle: false,
      },
      render: (text) => (text ? text : "N/A"),
    },
    {
      title: "Energy Certificate Type",
      dataIndex: "EnergyCertificateType",
      key: "EnergyCertificateType",
      width: 190,
      ellipsis: {
        showTitle: false,
      },
      render: (text) => (text ? text : "N/A"),
    },
    {
      title: "Energy Consumption (kWh/m²)",
      dataIndex: "EnergyConsumption",
      key: "EnergyConsumption",
      width: 280,
      ellipsis: {
        showTitle: false,
      },
      render: (text) => (text ? text : "N/A"),
    },
    {
      title: "Year According to Energy Certificate",
      dataIndex: "YearAccordingToEnergyCertificate",
      key: "YearAccordingToEnergyCertificate",
      width: 280,
      ellipsis: {
        showTitle: false,
      },
      render: (text) => (text ? text : "N/A"),
    },
    {
      title: "Property Description",
      dataIndex: "PropertyDescription",
      key: "PropertyDescription",
      width: 360,
      ellipsis: {
        showTitle: false,
      },
      render: (text) => (
        <Tooltip
          title={<Flex className="tooltip-text">{text ? text : "N/A"}</Flex>}
        >
          <Typography.Text
            className="description-col"
            ellipsis={{ tooltip: false }}
          >
            {text ? text : "N/A"}
          </Typography.Text>
        </Tooltip>
      ),
    },
    {
      title: "Equipment",
      dataIndex: "Equipment",
      key: "Equipment",
      width: 300,
      ellipsis: {
        showTitle: false,
      },
      render: (text) => (
        <Tooltip
          title={<Flex className="tooltip-text">{text ? text : "N/A"}</Flex>}
        >
          <Typography.Text
            className="description-col"
            ellipsis={{ tooltip: false }}
          >
            {text ? text : "N/A"}
          </Typography.Text>
        </Tooltip>
      ),
    },
    {
      title: "Location",
      dataIndex: "Location",
      key: "Location",
      width: 300,
      ellipsis: {
        showTitle: false,
      },
      render: (text) => (
        <Tooltip
          title={<Flex className="tooltip-text">{text ? text : "N/A"}</Flex>}
        >
          <Typography.Text
            className="description-col"
            ellipsis={{ tooltip: false }}
          >
            {text ? text : "N/A"}
          </Typography.Text>
        </Tooltip>
      ),
    },
    {
      title: "Latitude",
      dataIndex: "Latitude",
      key: "Latitude",
      width: 120,
      render: (text) => (text ? text : "N/A"),
    },
    {
      title: "Longitude",
      dataIndex: "Longitude",
      key: "Longitude",
      width: 120,
      render: (text) => (text ? text : "N/A"),
    },
    {
      title: "Action",
      key: "action",
      fixed: "right",
      width: 80,
      render: (records) => {
        return (
          <Space
            size="middle"
            style={{
              cursor: "pointer",
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
            }}
            onClick={() => handleTableAction(records)}
          >
            <TableActionIcon />
          </Space>
        );
      },
    },
  ];
};

/**
 * Dashboard component for the application. It displays a list of residential properties with different views
 * (Grid, List, Table) and allows users to customize the table layout, select and compare listings,
 * and perform various actions like Edit, Delete, Download, and Filter.
 *
 * @param {Array} TABLE_DATA - An array of objects representing the property listings.
 * @param {Array} TABLE_COLUMN - Array defining the columns of the table.
 * @param {Array} CURRENT_LISTING_VIEW - Array defining the available views for the listings (Grid, List, Table).
 * @param {Array} TABLE_ACTIONS_GRID - Array defining the action buttons available for each table row.
 * @returns {JSX.Element} The rendered Dashboard component with property listings.
 */
export const DashboardComponent = () => {
  // Default list of selected columns for the table
  const defaultCheckedList = TABLE_COLUMN().map((item) => item.key);
  // State for tracking the selected columns in the table
  const [checkedList, setCheckedList] = useState(() => {
    const storedCustomizeList = localStorage.getItem("DashboardCustomizeList");
    return storedCustomizeList
      ? JSON.parse(storedCustomizeList)
      : defaultCheckedList;
  });

  // State for storing selected filter options
  const [filterCheckedList, setFilterCheckedList] = useState({});
  // State for managing the current view (Grid/List/Table)
  const [activeView, setActiveView] = useState("Table");
  // State for tracking the "Select All" checkbox status
  const [checkedAll, setAllChecked] = useState(false);
  // State for storing selected rows in the table
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  // State for controlling the visibility of the Edit Modal
  const [isShowEditModal, setIsShowEditModal] = useState(false);
  // State for controlling the visibility of the Compare Listing Modal
  const [isShowCompareModal, setIsShowCompareModal] = useState(false);
  // Get responsive breakpoints to adjust layout based on screen size
  const screens = useBreakpoint();

  // Confirm dialog box
  const { confirm } = Modal;
  // Message API for displaying notification messages
  const [messageApi, contextHolder] = message.useMessage();

  // Create a form instance
  const [form] = Form.useForm();

  const auth = useAuth();
  const UserID = auth.user?.profile.sub;
  const Username = auth.user.profile.name;
  const Email = auth.user.profile.email;

  // Determine the flex direction (row or column) based on screen size
  const flexDirection = screens.lg ? "row" : "column";

  // Column options for table customization, allowing users to show/hide columns
  const columnOption = TABLE_COLUMN().map(({ key, title }) => ({
    label: title,
    value: key,
  }));

  const handleTableAction = (record) => {
    setIsShowEditModal(true);
  };

  // When checkedList changes, update it in localStorage
  useEffect(() => {
    localStorage.setItem("DashboardCustomizeList", JSON.stringify(checkedList));
  }, [checkedList]);

  // Update the visibility of columns based on the selected options
  const updateTableColumn = TABLE_COLUMN(handleTableAction).map((item) => ({
    ...item,
    hidden: !checkedList.includes(item.key),
  }));

  // Fetch all listings for the current user, skipping the query if UserID is falsy
  const { data: ListingData, isLoading: listingDataLoader } =
    useGetAllListingQuery(UserID, {
      skip: !UserID,
    });

  // Fetch user settings for the current user, skipping the query if UserID is falsy
  const { data: userSettingsData } = useGetUserSettingsQuery(UserID, {
    skip: !UserID,
  });

  // Define a mutation to update a listing
  const [updateListings, { isLoading: updateListingLoader }] =
    useUpdateListingMutation();

  // Define a mutation to delete a listing
  const [deleteListings, { isLoading: deleteListingLoader }] =
    useDeleteListingMutation();

  const storedCompareData = localStorage.getItem("CompareListingData");

  /**
   * Handle updating the comparison data in local storage.
   *
   * It filters the currently selected listings from ListingData and updates the
   * "CompareListingData" key in local storage. If no listings are selected, it
   * reverts to the previously stored value.
   */
  const handleComparisonData = () => {
    const selectedListings = ListingData.listings.filter((listing) =>
      selectedRowKeys.includes(listing.ListingID)
    );
    localStorage.setItem(
      "CompareListingData",
      selectedListings?.length === 0
        ? storedCompareData
        : JSON.stringify(selectedListings)
    );
  };

  // Handle deleting a listing by ID
  const handleDeleteListing = async (ListingID) => {
    const payload = { UserID, ListingID };
    // Call the deleteListings mutation and wait for the response
    let response = await deleteListings(payload);
    // Check if the response indicates success
    if (response?.data?.success) {
      // TO DO: handle success response
    }
  };

  // Handle row selection changes in the table
  const onSelectChange = (newSelectedRowKeys) => {
    // Update the selected row keys state
    setSelectedRowKeys(newSelectedRowKeys);
  };

  // Handle "Select All" checkbox state changes
  const onChangeAllChecked = (e) => {
    // Get the checked state of the checkbox
    const checked = e.target.checked;
    // Call the handleSelectItem function to update the selection
    handleSelectItem(checked);
  };

  // Define the row selection settings for the table
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    onSelect: (selected) => {
      setSelectedRowKeys(selected);
    },
    onSelectAll: (selected) => {
      handleSelectItem(selected);
    },
  };

  // Handle selection or deselection of all rows
  const handleSelectItem = (select) => {
    // Update the all-checked state
    setAllChecked(select);
    // If selecting all rows, get all row keys and update the selected row keys state
    if (select) {
      const allRowKeys = formatListingsData(ListingData?.listings ?? [])?.map(
        (item) => item.key
      );
      setSelectedRowKeys(allRowKeys);
    } else {
      // If deselecting all rows, clear the selected row keys state
      setSelectedRowKeys([]);
    }
  };

  // Handle form submission for editing a property
  const handleOnSubmit = async (userData) => {
    // Create an updated settings object with the new user data
    const updatedSettings = {
      UserID,
      Username,
      Email,
      EquityRatio: userData?.EquityRatio,
      InterestRate: userData?.InterestRate,
    };
    // Call the updateListings mutation and wait for the response
    let response = await updateListings(updatedSettings);
    // Check if the response indicates success
    if (response?.data?.success) {
      // Display a success message
      messageApi.open({
        type: "success",
        content: response?.data?.message,
      });
      // Reset the form fields
      form.resetFields();
      // Close the edit modal
      setIsShowEditModal(false);
    }
  };

  /**
   * Display a confirmation modal to confirm if the user wants to delete the
   * currently selected list.
   * @function showDeleteConfirm
   */
  const showDeleteConfirm = () => {
    confirm({
      title: "Are you sure delete this List?",
      icon: <ExclamationCircleFilled />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        handleDeleteListing(selectedRowKeys);
      },
    });
  };

  /**
   * Additional "key" property added to each listing for Table Selection handling.
   * @param {Array} listings The array of listings to be formatted.
   * @return {Array} The formatted array of listings.
   */
  const formatListingsData = (listings) => {
    return listings?.map((listing) => {
      return {
        ...listing,
        key: listing.ListingID,
      };
    });
  };

  return (
    <Flex vertical style={HomePageStyles.FlexFullWidth}>
      {contextHolder}
      {/* Header section with title and view switching buttons */}
      <Flex style={HomePageStyles.FlexAlignBetween}>
        <Flex style={HomePageStyles.FlexCenterWithGap}>
          <Typography style={HomePageStyles.TitleText}>
            Find Residence Properties
          </Typography>
          <Typography style={HomePageStyles.SubTitleText}>
            ({ListingData?.listings?.length ?? 0} Found)
          </Typography>
        </Flex>
        <Flex>
          {/* Buttons to switch between Grid, List, and Table views */}
          {CURRENT_LISTING_VIEW.map((item) => {
            return (
              <Button
                key={item.id}
                type="text"
                icon={item.icon}
                style={{
                  ...HomePageStyles.ButtonStyle,
                  ...("Table" === item.label
                    ? HomePageStyles.ButtonActive
                    : {}),
                }}
                disabled={item.label !== "Table"}
                onClick={() => setActiveView(item.label)}
              >
                {item.label}
              </Button>
            );
          })}
        </Flex>
      </Flex>

      {/* Section to handle table customization and actions */}
      <Flex style={{ ...HomePageStyles.FlexWithMarginTop, flexDirection }}>
        <Flex style={HomePageStyles.FlexCenterWithGap}>
          <Typography style={HomePageStyles.SelectCompareTitle}>
            Select to compare
          </Typography>
          <Typography style={HomePageStyles.SubTitleText}>
            <Checkbox checked={checkedAll} onChange={onChangeAllChecked}>
              All
            </Checkbox>
          </Typography>
        </Flex>
        <Flex style={{ gap: "10px" }}>
          {/* Dropdown for customizing the table columns */}
          <Dropdown
            menu={{
              items: CustomizeDropdown(
                checkedList,
                setCheckedList,
                columnOption
              ),
            }}
            trigger={["click"]}
          >
            <Button
              type="text"
              icon={<CustomizeIcon />}
              style={HomePageStyles.CustomizeButtonStyle}
            >
              Customize
            </Button>
          </Dropdown>
          {/* Table action buttons such as Edit, Delete, Download, and Filter */}
          {TABLE_ACTIONS_GRID.map((item) => {
            return (
              <>
                {" "}
                {item.label !== "Filter" ? (
                  <Button
                    key={item.id}
                    type="text"
                    icon={item.icon}
                    style={{
                      ...HomePageStyles.TableActionButton,
                      color: `${item.color}`,
                      border: `${item.border}`,
                      backgroundColor: `${item.bgColor}`,
                      marginInline: item.label === "Filter" && "10px",
                    }}
                    disabled={
                      (item.label === "Delete" || item.label === "Download") &&
                      selectedRowKeys.length === 0
                        ? true
                        : false
                    }
                    onClick={() => {
                      setActiveView(item.label);
                      if (item.label === "Edit") {
                        setIsShowEditModal(true);
                      }
                      if (item.label === "Delete") {
                        showDeleteConfirm();
                      }
                      if (item.label === "Compare Listing") {
                        handleComparisonData();
                        setIsShowCompareModal(true);
                      }
                    }}
                  >
                    {item.label}
                  </Button>
                ) : (
                  <Dropdown
                    placement="bottom"
                    menu={{
                      items: FilterDropdown(
                        filterCheckedList,
                        setFilterCheckedList,
                        filterData
                      ),
                    }}
                    trigger={["click"]}
                    overlayStyle={{ width: "320px" }}
                    dropdownRender={(menu) => (
                      <div style={HomePageStyles.MenuBox}>{menu}</div>
                    )}
                  >
                    <Button
                      type="text"
                      icon={item.icon}
                      style={{
                        ...HomePageStyles.TableActionButton,
                        color: `${item.color}`,
                        border: `${item.border}`,
                        backgroundColor: `${item.bgColor}`,
                        marginInline: item.label === "Filter" && "10px",
                      }}
                    >
                      {item.label}
                    </Button>
                  </Dropdown>
                )}
              </>
            );
          })}
        </Flex>
      </Flex>

      {/* Section to render the table with data and row selection */}
      <Flex style={HomePageStyles.FlexCenterWithMarginTop}>
        <CustomizeTable
          dataSource={formatListingsData(ListingData?.listings ?? [])}
          columns={updateTableColumn}
          rowSelection={rowSelection}
          isLoading={listingDataLoader}
        />
      </Flex>
      {/* Section to render Edit Model */}
      <EditModel
        isShowEditModal={isShowEditModal}
        setIsShowEditModal={setIsShowEditModal}
        handleOnSubmit={handleOnSubmit}
        initialData={userSettingsData?.settings}
        form={form}
        isLoading={updateListingLoader}
      />

      <CustomModel
        title={"View Compare Listing"}
        onOpen={isShowCompareModal}
        onCancel={() => setIsShowCompareModal(false)}
        width={1200}
      >
        <ComparePropertyComponent />
      </CustomModel>
    </Flex>
  );
};
