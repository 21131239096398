import { Col, Layout, Row } from "antd";
import React from "react";
import { AuthLayoutStyle } from "../../assets/css/AuthLayout";
import "../../assets/css/Global.css";

const AuthLayout = ({ children }) => {
  return (
    <Layout>
      <Row style={AuthLayoutStyle.LayoutRow}>
        <Col xs={24} md={12} style={AuthLayoutStyle.LeftCol}>
          <div style={AuthLayoutStyle.LeftColDiv}>{children}</div>
        </Col>
        <Col xs={0} md={12} style={AuthLayoutStyle.RightCol} />
      </Row>
    </Layout>
  );
};

export default AuthLayout;
