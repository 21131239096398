import { baseAPI } from "./baseApi";

/**
 * An API client for interacting with listing-related endpoints.
 *
 * This component exports four hooks:
 * - `useGetAllListingQuery`: Retrieves all listings for a given user ID.
 * - `useGetUserSettingsQuery`: Retrieves user settings for a given user ID.
 * - `useDeleteListingMutation`: Deletes a listing.
 * - `useUpdateListingMutation`: Updates user settings and listings.
 *
 * These hooks are generated by the `ListingApi` client, which is created by injecting endpoints into a `baseAPI`. 
 * Each endpoint specifies a URL, method, and payload (if applicable), as well as tags that are provided or invalidated upon successful execution.
 */
export const ListingApi = baseAPI.injectEndpoints({
  endpoints: (builder) => ({
    getAllListing: builder.query({
      query: (userID) => ({
        url: "/api/userListingData/" + userID,
      }),
      providesTags: ["Listing"],
    }),
    getUserSettings: builder.query({
      query: (userID) => ({
        url: "/api/userSettings/" + userID,
      }),
      providesTags: ["UserSettings"],
    }),
    deleteListing: builder.mutation({
      query: (payload) => ({
        url: "/api/userListingData/delete",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Listing"],
    }),
    updateListing: builder.mutation({
      query: (payload) => ({
        url: "/api/userSettings/saveSettings",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["UserSettings", "Listing"],
    }),
  }),
});

export const {
  useGetAllListingQuery,
  useGetUserSettingsQuery,
  useDeleteListingMutation,
  useUpdateListingMutation,
} = ListingApi;
