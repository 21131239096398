import {
  Button,
  Checkbox,
  Dropdown,
  Flex,
  Grid,
  Tooltip,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { HomePageStyles } from "../../assets/css/Home";
import { ComparePropertyStyles } from "../../assets/css/PropertyCompare";
import { CustomizeIcon } from "../../assets/images/Icons";
import { ComparisonTable } from "./ComparisonTable/ComparisonTable";
import { CustomizeDropdown } from "./CustomizeDropdown/CustomizeDropdown";
const { useBreakpoint } = Grid;

export const TABLE_COLUMN = [
  {
    title: "ID",
    dataIndex: "ListingID",
    key: "ListingID",
    fixed: "left",
    ellipsis: {
      showTitle: false,
    },
    render: (text) => (text ? text : "N/A"),
  },
  {
    title: "Source",
    dataIndex: "Source",
    key: "Source",
    ellipsis: {
      showTitle: false,
    },
    render: (text) => (text ? text : "N/A"),
  },
  {
    title: "Property Type",
    dataIndex: "PropertyType",
    key: "PropertyType",
    ellipsis: {
      showTitle: false,
    },
    render: (text) => (text ? text : "N/A"),
  },
  {
    title: "Street",
    dataIndex: "Street",
    key: "Street",
    ellipsis: {
      showTitle: false,
    },
    render: (text) => (text ? text : "N/A"),
  },
  {
    title: "House Number",
    dataIndex: "HouseNumber",
    key: "HouseNumber",
    ellipsis: {
      showTitle: false,
    },
    render: (text) => (text ? text : "N/A"),
  },
  {
    title: "Postal Code",
    dataIndex: "PostalCode",
    key: "PostalCode",
    ellipsis: {
      showTitle: false,
    },
    render: (text) => (text ? text : "N/A"),
  },
  {
    title: "City",
    dataIndex: "City",
    key: "City",
    ellipsis: {
      showTitle: false,
    },
    render: (text) => (text ? text : "N/A"),
  },
  {
    title: "District",
    dataIndex: "District",
    key: "District",
    ellipsis: {
      showTitle: false,
    },
    render: (text) => (text ? text : "N/A"),
  },
  {
    title: "State",
    dataIndex: "State",
    key: "State",
    ellipsis: {
      showTitle: false,
    },
    render: (text) => (text ? text : "N/A"),
  },
  {
    title: "Purchase Price",
    dataIndex: "PurchasePrice",
    key: "PurchasePrice",
    ellipsis: {
      showTitle: false,
    },
    render: (text) => (text ? `${text} €` : "N/A"),
  },
  {
    title: "Price",
    dataIndex: "PricePerSquareMeter",
    key: "PricePerSquareMeter",
    ellipsis: {
      showTitle: false,
    },
    render: (text) => (text ? `${text} €` : "N/A"),
  },
  {
    title: "Rooms",
    dataIndex: "Rooms",
    key: "Rooms",
    ellipsis: {
      showTitle: false,
    },
    render: (text) => (text ? text : "N/A"),
  },
  {
    title: "Area (m²)",
    dataIndex: "Area",
    key: "Area",
    ellipsis: {
      showTitle: false,
    },
    render: (text) => (text ? `${text} m²` : "N/A"),
  },
  {
    title: "Usable Area (m²)",
    dataIndex: "UsableArea",
    key: "UsableArea",
    ellipsis: {
      showTitle: false,
    },
    render: (text) => (text ? `${text} m²` : "N/A"),
  },
  {
    title: "Land Area (m²)",
    dataIndex: "LandArea",
    key: "LandArea",
    ellipsis: {
      showTitle: false,
    },
    render: (text) => (text ? `${text} m²` : "N/A"),
  },
  {
    title: "Building Type",
    dataIndex: "BuildingType",
    key: "BuildingType",
    ellipsis: {
      showTitle: false,
    },
    render: (text) => (text ? text : "N/A"),
  },
  {
    title: "Floors",
    dataIndex: "NumberOfFloors",
    key: "NumberOfFloors",
    ellipsis: {
      showTitle: false,
    },
    render: (text) => (text ? text : "N/A"),
  },
  {
    title: "Available From",
    dataIndex: "AvailableFrom",
    key: "AvailableFrom",
    ellipsis: {
      showTitle: false,
    },
    render: (text) => (text ? text : "N/A"),
  },
  {
    title: "Bedrooms",
    dataIndex: "Bedrooms",
    key: "Bedrooms",
    ellipsis: {
      showTitle: false,
    },
    render: (text) => (text ? text : "N/A"),
  },
  {
    title: "Garage/Parking",
    dataIndex: "GarageOrParking",
    key: "GarageOrParking",
    ellipsis: {
      showTitle: false,
    },
    render: (text) => (text ? text : "N/A"),
  },
  {
    title: "Buyer Commission",
    dataIndex: "BuyerCommission",
    key: "BuyerCommission",
    ellipsis: {
      showTitle: false,
    },
    render: (text) => (text ? text : "N/A"),
  },
  {
    title: "Year of Construction",
    dataIndex: "YearOfConstruction",
    key: "YearOfConstruction",
    ellipsis: {
      showTitle: false,
    },
    render: (text) => (text ? text : "N/A"),
  },
  {
    title: "Construction Phase",
    dataIndex: "ConstructionPhase",
    key: "ConstructionPhase",
    ellipsis: {
      showTitle: false,
    },
    render: (text) => (text ? text : "N/A"),
  },
  {
    title: "Renovation",
    dataIndex: "Renovation",
    key: "Renovation",
    ellipsis: {
      showTitle: false,
    },
    render: (text) => (text ? text : "N/A"),
  },
  {
    title: "Property Condition",
    dataIndex: "PropertyCondition",
    key: "PropertyCondition",
    ellipsis: {
      showTitle: false,
    },
    render: (text) => (text ? text : "N/A"),
  },
  {
    title: "Quality of Equipment",
    dataIndex: "QualityOfEquipment",
    key: "QualityOfEquipment",
    ellipsis: {
      showTitle: false,
    },
    render: (text) => (text ? text : "N/A"),
  },
  {
    title: "Hausgeld",
    dataIndex: "Hausgeld",
    key: "Hausgeld",
    ellipsis: {
      showTitle: false,
    },
    render: (text) => (text ? text : "N/A"),
  },
  {
    title: "Main Energy Source",
    dataIndex: "MainEnergySource",
    key: "MainEnergySource",
    ellipsis: {
      showTitle: false,
    },
    render: (text) => (text ? text : "N/A"),
  },
  {
    title: "Energy Certification",
    dataIndex: "EnergyCertificate",
    key: "EnergyCertificate",
    ellipsis: {
      showTitle: false,
    },
    render: (text) => (text ? text : "N/A"),
  },
  {
    title: "Energy Certificate Type",
    dataIndex: "EnergyCertificateType",
    key: "EnergyCertificateType",
    ellipsis: {
      showTitle: false,
    },
    render: (text) => (text ? text : "N/A"),
  },
  {
    title: "Energy Consumption (kWh/m²)",
    dataIndex: "EnergyConsumption",
    key: "EnergyConsumption",
    ellipsis: {
      showTitle: false,
    },
    render: (text) => (text ? text : "N/A"),
  },
  {
    title: "Year According to Energy Certificate",
    dataIndex: "YearAccordingToEnergyCertificate",
    key: "YearAccordingToEnergyCertificate",
    ellipsis: {
      showTitle: false,
    },
    render: (text) => (text ? text : "N/A"),
  },
  {
    title: "Property Description",
    dataIndex: "PropertyDescription",
    key: "PropertyDescription",
    ellipsis: {
      showTitle: false,
    },
    render: (text) => (
      <Tooltip title={<Flex className="tooltip-text">{text}</Flex>}>
        <Typography.Text
          className="description-col"
          ellipsis={{ tooltip: false }}
        >
          {text ? text : "N/A"}
        </Typography.Text>
      </Tooltip>
    ),
  },
  {
    title: "Equipment",
    dataIndex: "Equipment",
    key: "Equipment",
    ellipsis: {
      showTitle: false,
    },
    render: (text) => (
      <Tooltip title={<Flex className="tooltip-text">{text}</Flex>}>
        <Typography.Text
          className="description-col"
          ellipsis={{ tooltip: false }}
        >
          {text ? text : "N/A"}
        </Typography.Text>
      </Tooltip>
    ),
  },
  {
    title: "Location",
    dataIndex: "Location",
    key: "Location",
    ellipsis: {
      showTitle: false,
    },
    width: 300,
    render: (text) => (
      <Tooltip title={<Flex className="tooltip-text">{text}</Flex>}>
        <Typography.Text
          className="description-col"
          ellipsis={{ tooltip: false }}
        >
          {text ? text : "N/A"}
        </Typography.Text>
      </Tooltip>
    ),
  },
  {
    title: "Latitude",
    dataIndex: "Latitude",
    key: "Latitude",
    render: (text) => (text ? text : "N/A"),
  },
  {
    title: "Longitude",
    dataIndex: "Longitude",
    key: "Longitude",
    render: (text) => (text ? text : "N/A"),
  },
];

/**
 * ComparePropertyComponent allows users to view and compare properties using a customizable table.
 * It provides functionality to show/hide table columns, select/deselect all rows, and handle row selection.
 *
 * @returns {JSX.Element} A component that renders the property comparison table with customizable options.
 */
export const ComparePropertyComponent = () => {
  // Default list of selected columns
  const defaultCheckedList = TABLE_COLUMN.map((item) => item.key);
  // State for columns displayed in the table
  const [checkedList, setCheckedList] = useState(() => {
    const storedCustomizeList = localStorage.getItem("CompareCustomizeList");
    return storedCustomizeList
      ? JSON.parse(storedCustomizeList)
      : defaultCheckedList;
  });

  // State for storing CompareListingData
  const [compareListingData, setCompareListingData] = useState([]);
  // Get CompareListingData from local storage
  const storedCompareData = localStorage.getItem("CompareListingData");

  // State to check/uncheck all items in the table
  const [checkedAll, setAllChecked] = useState(false);
  // State to track selected rows in the table
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  // Column options for customization, allowing users to show/hide columns
  const columnOption = TABLE_COLUMN.map(({ key, title }) => ({
    label: title,
    value: key,
  }));
  // Get responsive breakpoints to adjust layout based on screen size
  const screens = useBreakpoint();
  // Determine the flex direction (row or column) based on screen size
  const flexDirection = screens.lg ? "row" : "column";

  // Update table column visibility based on user's selection
  const updateTableColumn = TABLE_COLUMN.map((item) => ({
    ...item,
    hidden: !checkedList.includes(item.key),
  }));

  // When checkedList changes, update it in localStorage
  useEffect(() => {
    localStorage.setItem("CompareCustomizeList", JSON.stringify(checkedList));
  }, [checkedList]);

  useEffect(() => {
    if (storedCompareData) {
      setCompareListingData(
        storedCompareData ? JSON.parse(storedCompareData) : []
      );
    }
  }, [storedCompareData]);

  /**
   * Handles the comparison checkbox click event by removing the selected listing
   * from the compareListingData state and updating the local storage.
   *
   * @param {string} ListingID - Unique identifier of the listing to be removed.
   */
  const handleComparisonCheckbox = (ListingID) => {
    const updatedCompareData = compareListingData.filter(
      (listing) => listing.ListingID !== ListingID
    );
    setCompareListingData(updatedCompareData);
    localStorage.setItem(
      "CompareListingData",
      JSON.stringify(updatedCompareData)
    );
  };
  // Handle row selection change in the table
  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  // Handle "Select All" checkbox state change
  const onChangeAllChecked = (e) => {
    const checked = e.target.checked;
    handleSelectItem(checked);
  };

  //   // Row selection settings for the table
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    onSelect: (selected) => {
      setSelectedRowKeys(selected);
    },
    onSelectAll: (selected) => {
      handleSelectItem(selected);
    },
  };

  /**
   * Handles selection/deselection of all rows in the table.
   * @param {boolean} select - Whether to select or deselect all rows.
   */
  const handleSelectItem = (select) => {
    setAllChecked(select);
    if (select) {
      // Generate an array of all row keys
      const allRowKeys = compareListingData?.map((item) => item.key);
      setSelectedRowKeys(allRowKeys);
    } else {
      // Clear the selected row keys array
      setSelectedRowKeys([]);
    }
  };

  return (
    <Flex vertical style={ComparePropertyStyles.Container}>
      <div style={ComparePropertyStyles.ImageContainer}>
        <Flex vertical style={ComparePropertyStyles.Overlay}>
          <Typography style={ComparePropertyStyles.TitleText}>
            Compare Property
          </Typography>
          <Typography style={ComparePropertyStyles.SubTitleText}>
            Explore exquisite properties. From urban chic to rural serenity,
            find your dream home. Elevate your lifestyle – your future home is
            just a click away
          </Typography>
        </Flex>
      </div>
      <Flex>
        {/* Section to handle table customization and actions */}
        <Flex style={{ ...HomePageStyles.FlexWithMarginTop, flexDirection }}>
          <Flex style={HomePageStyles.FlexCenterWithGap}>
            <Typography style={HomePageStyles.SelectCompareTitle}>
              Select to compare
            </Typography>
            <Typography style={HomePageStyles.SubTitleText}>
              <Checkbox checked={checkedAll} onChange={onChangeAllChecked}>
                All
              </Checkbox>
            </Typography>
          </Flex>
          <Flex style={{ gap: "10px" }}>
            {/* Dropdown for customizing the table columns */}
            <Dropdown
              menu={{
                items: CustomizeDropdown(
                  checkedList,
                  setCheckedList,
                  columnOption
                ),
              }}
              trigger={["click"]}
            >
              <Button
                type="text"
                icon={<CustomizeIcon />}
                style={HomePageStyles.CustomizeButtonStyle}
              >
                Customize
              </Button>
            </Dropdown>
          </Flex>
        </Flex>
      </Flex>
      <Flex vertical>
        {" "}
        <Flex style={ComparePropertyStyles.ComparisonSection}>
          <ComparisonTable
            dataSource={compareListingData ?? []}
            columns={updateTableColumn}
            rowSelection={rowSelection}
            handleComparisonCheckbox={handleComparisonCheckbox}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};
