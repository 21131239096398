import { Button } from "antd";

/**
 * A reusable button component for form submission.
 * 
 * @param {string} type - The button type, e.g. "primary", "default", "dashed", "link", "text", "ghost".
 * @param {string} htmlType - The button's html type, e.g. "button", "submit", "reset".
 * @param {boolean} block - Whether the button is a block element or not.
 * @param {ReactNode} children - The content of the button.
 * @param {function} onClick - The function to be executed when the button is clicked.
 * @param {string} buttonClass - The CSS class name for the button.
 * @param {boolean} loading - Whether the button is loading or not.
 * @param {boolean} disabled - Whether the button is disabled or not.
 * @param {object} style - The CSS style object for the button.
 */

const FormButton = ({
  type = "primary",
  htmlType = "button",
  block = false,
  children,
  onClick,
  buttonClass = "",
  loading = false,
  disabled = false,
  style = {},
}) => {
  return (
    <Button
      type={type}
      htmlType={htmlType}
      block={block}
      onClick={onClick}
      className={buttonClass}
      loading={loading}
      disabled={disabled}
      style={style}
    >
      {children}
    </Button>
  );
};

export default FormButton;
