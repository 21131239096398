import { Checkbox, Flex, Table, Tooltip, Typography } from "antd";

/**
 * Transposes the table data so that columns become rows.
 * @param {Array} columns - Array of column objects, each containing a key, title, and optional render function.
 * @param {Array} dataSource - Array of row data, where each object corresponds to a row in the original table.
 *
 * @returns {Array} Transposed data where each original column is converted into a row, with dynamic keys for each
 *                  column (e.g., `col_0`, `col_1`, etc.) representing the original rows.
 */
const getTransposedData = (columns, dataSource) => {
  return columns.map((column) => {
    const row = {
      key: column.key, // Column key becomes the row key
      title: column.title, // Column title becomes the row title
      hidden: column.hidden, // Maintain hidden status if applicable
    };

    dataSource.forEach((data, index) => {
      row[`col_${index}`] = column.render // Apply render function if available
        ? column.render(data[column.dataIndex])
        : data[column.dataIndex]; // Else use raw data
    });

    return row;
  });
};

/**
 * ComparisonTable component renders a table for comparing properties.
 *
 * @param {Array} dataSource - Array of data to be displayed in the table.
 * @param {Array} columns - Array of objects representing the columns of the table.
 * @param {object} rowSelection - Object containing row selection configuration for the table.
 * @param {boolean} isLoading - Whether the table should show a loading state.
 * @param {function} handleComparisonCheckbox - Function to be called when a checkbox is clicked.
 *
 * @returns {JSX.Element} A table component with customizable columns, row selection, and scroll options.
 */
export const ComparisonTable = ({
  dataSource,
  columns,
  rowSelection = {},
  isLoading = false,
  handleComparisonCheckbox,
}) => {
  // Filter out hidden columns dynamically
  const visibleColumns = columns.filter((col) => !col.hidden);
  // Generate the columns for the transposed data
  const transposedColumns = [
    {
      title: "",
      dataIndex: "title",
      key: "title",
      fixed: "left",
      width: 280,
      render: (text) => <b>{text}</b>,
      onHeaderCell: () => ({
        style: { backgroundColor: "#FFF" },
      }),
    },
    ...dataSource.map((data, index) => {
      return {
        title: (
          <Flex vertical>
            <Flex style={{ width: "100%", position: "relative" }}>
              <Flex
                style={{
                  position: "absolute",
                  right: 10,
                  top: 5,
                }}
              >
                {/* Checkbox with click handler to remove listing */}
                <Checkbox
                  checked
                  onChange={() => handleComparisonCheckbox(data.ListingID)}
                />
              </Flex>
              <img
                src={data?.ThumbnailURL}
                alt="property"
                height={230}
                width={300}
                /*style={{ width: "100%" }}*/
              />
            </Flex>
            <Tooltip
              title={<Flex className="tooltip-text">{data.Address}</Flex>}
            >
              <Typography.Text
                className="description-col"
                ellipsis={{ tooltip: false }}
                style={{
                  color: "#1F1F1F",
                  fontWeight: "500",
                  fontSize: "22px",
                }}
              >
                {data.Address}
              </Typography.Text>
            </Tooltip>
            <Typography style={{ color: "#98A2B3", fontSize: "18px" }}>
              {data.Bedrooms ? `${data.Bedrooms} br ` : ""}
              {data.NumberOfFloors ? `${data.NumberOfFloors} f ` : ""}
              {data.Area ? `${data.Area} sqf ` : ""}
            </Typography>
          </Flex>
        ),
        dataIndex: `col_${index}`,
        key: `col_${index}`,
        onHeaderCell: () => ({
          style: { backgroundColor: "#FFF" },
        }),
      };
    }),
  ];

  const transposedData = getTransposedData(visibleColumns, dataSource);

  return (
    <Table
      bordered
      style={{ width: "100%" }}
      columns={transposedColumns}
      dataSource={transposedData}
      pagination={false}
      scroll={{
        x: 1500, // Enable horizontal scrolling for wide tables
      }}
      //   rowSelection={rowSelection}
      loading={isLoading}
    />
  );
};
