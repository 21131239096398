export const AppLayoutStyle = {
  HeaderMain: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#FFFFFF",
    width: "100%",
    padding: "17px 2.5% 20px 3% !important",
    height: "100px",
  },
  RowStyle: (isResponsive) => ({
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: isResponsive ? "space-between" : "",
  }),
  ColLogoStyle: {
    display: "flex",
    alignItems: "center",
  },
  ColNavBarStyle: {
    display: "flex",
    gap: "25px",
    paddingTop: "10px",
  },
  ColAuthButtons: {
    display: "flex",
    gap: "20px",
    justifyContent: "flex-end",
  },
  NavTypography: (selectedItem, page) => ({
    color: selectedItem === page ? "#1DB3B3" : "#344054",
    fontWeight: selectedItem === page ? "700" : "400",
    display: "flex",
    fontSize: "18px",
    textTransform: "capitalize",
    cursor: "pointer",
  }),
  ButtonLogin: {
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "24px",
    textTransform: "uppercase",
    padding: "18px",
  },
  ButtonSignup: {
    backgroundColor: "#1DB3B3",
    color: "#FFFFFF",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "24px",
    textTransform: "uppercase",
    padding: "18px",
  },
  MenuIconStyle: {
    fontSize: "20px",
    cursor: "pointer",
  },
  DrawerFlexVertical: {
    display: "flex",
    gap: "20px",
    flexDirection: "column",
  },
  DrawerCloseIcon: {
    fontSize: "16px",
  },
  DrawerInnerFlex: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  ContentStyle: {
    backgroundColor: "#FFFFFF",
    width: "100%",
    padding: "0px 2.5% 0px 2.5%",
    overflowY: "auto",
  },
  FooterMain: {
    display: "flex",
    backgroundColor: "#1F1F1F",
    width: "100%",
    padding: "30px 2.5% 30px 2.5%",
    color: "#FFFFFF",
    marginTop: "auto",
  },
  FooterCol: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    gap: "20px",
    flexDirection: "column",
    marginTop: "15px",
  },
  ContactUsTypography: {
    color: "#FFFFFF",
    fontSize: "18px",
  },
  ContactCard: {
    backgroundColor: "#363636",
    padding: "10px",
    borderRadius: "14px",
  },
  ContactLabel: {
    color: "#FFFFFF",
    fontSize: "16px",
  },
  ContactValue: {
    color: "#FFFFFF",
    fontWeight: "500",
    fontSize: "16px",
  },
  FlexNavColumn: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
  FlexAuthButtonsColumn: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    justifyContent: "space-between",
  },
};
