// HomePageStyles.js
export const HomePageStyles = {
  FlexFullWidth: {
    width: "100%",
  },
  FlexAlignBetween: {
    alignItems: "flex-start",
    justifyContent: "space-between",
    width: "100%",
  },
  FlexCenterWithGap: {
    alignItems: "center",
    justifyContent: "center",
    gap: "10px",
  },
  TitleText: {
    fontSize: "24px",
    color: "#1F1F1F",
    fontWeight: "600",
  },
  SubTitleText: {
    fontSize: "20px",
    color: "#1F1F1F",
  },
  ButtonStyle: {
    color: "#1F1F1F",
    fontSize: "16px",
    padding: "19px 15px",
  },
  ButtonActive: {
    border: "1px solid #4AC2C2",
    backgroundColor: "#E8F7F7",
  },
  FlexWithMarginTop: {
    alignItems: "flex-start",
    justifyContent: "space-between",
    width: "100%",
    marginTop: "20px",
  },
  SelectCompareTitle: {
    fontSize: "20px",
    color: "#1F1F1F",
    fontWeight: "500",
  },
  CustomizeButtonStyle: {
    color: `#157FA7`,
    backgroundColor: `#E9F7FD`,
    fontSize: "16px",
    padding: "19px 15px",
  },
  TableActionButton: {
    fontSize: "16px",
    padding: "19px 15px",
  },
  FlexCenterWithMarginTop: {
    alignItems: "center",
    justifyContent: "center",
    gap: "10px",
    marginTop: "30px",
  },
  CustomizeTableText: {
    color: "#344054",
    fontWeight: 500,
  },
  FlexFullWidth: {
    width: "100%",
  },
  ColumnText: {
    width: "100%",
    color: "#344054",
    fontWeight: 500,
    marginLeft: "10px",
  },
  RightIcon: {
    fontSize: "12px",
  },
  RowText: {
    width: "100%",
    color: "#344054",
    fontWeight: 500,
    marginLeft: "10px",
  },
  MenuBox: {
    maxHeight: "60vh",
    overflowY: "auto",
    border: "1px solid #D0D5DD",
    borderRadius: "12px",
    backgroundColor: "#FFF",
  },
};

export const FilterDropdownStyle = {
  FlexFullWidth: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
  },
  PriceRangeCol: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  PriceRangeTypography: {
    color: "#1F1F1F",
    backgroundColor: "#E8E8E8",
    padding: "2px 6px",
    borderRadius: "5px",
  },
  CounterControl: {
    color: "#1F1F1F",
    backgroundColor: "#EAECF0",
    borderRadius: "5px",
    alignItems: "center",
    justifyContent: "space-between",
    width: "110px",
    height: "31px",
  },
  CounterButton: {
    border: "1px solid #D0D5DD",
    borderRadius: "4px",
    padding: "3px 6px",
    backgroundColor: "#fff",
    cursor: "pointer",
  },
  TitleTypography: {
    color: "#1F1F1F",
    fontWeight: "500",
    fontSize: "18px",
  },
  LabelTypography: {
    color: "#1F1F1F",
    fontSize: "16px",
  },
  FlexColumn: {
    width: "100%",
    flexDirection: "column",
  },
  PriceInputWrapper: {
    display: "flex",
    gap: "10px",
    width: "100%",
  },
  PriceInputTypography: {
    color: "#344054",
    fontWeight: "500",
    marginBottom: "5px",
  },
  PriceInputContainer: {
    width: "100%",
  },
};

export const EditModelStyle = {
  ModalFooter: {
    justifyContent: "space-between",
    gap: "20px",
  },
  Divider: {
    margin: "0px",
  },
  DividerBottomMargin: {
    margin: "0px",
    marginBottom: "10px",
  },
  TypographyTitle: {
    fontSize: "18px",
    fontWeight: "500",
    color: "#101828",
    marginBlock: "8px",
  },
  FlexAlignCenter: {
    alignItems: "center",
    justifyContent: "space-between",
  },
  SettingIconWrapper: {
    border: "1px solid #D0D5DD",
    borderRadius: "4px",
    padding: "6px",
  },
  InputNumberPrefix: {
    color: "#98A2B3",
    fontSize: "16px",
  },
};
