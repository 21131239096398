import { Flex } from "antd";
import React, { Suspense } from "react";
import { useAuth } from "react-oidc-context";
import LoadingSvg from "./assets/images/loader-icon.gif";
import "./index.css";
import MainLayout from "./layouts/AppLayout/AppLayout";
import AuthLayout from "./layouts/AuthLayout/AuthLayout";
import AppRoutes from "./routes";

function App() {
  const auth = useAuth();

  if (auth.isLoading) {
    return (
      <Flex
        style={{
          justifyContent: "center",
          alignItems: "center",
          height: "80vh",
        }}
      >
        <img src={LoadingSvg} height={200} width={200} alt="LoadingSvg" />
      </Flex>
    );
  }

  if (auth.isAuthenticated) {
    localStorage.setItem("access_token", auth.user?.access_token);
  }

  if (auth.error) {
    localStorage.removeItem("access_token", auth.user?.access_token);
    return <div>Authentication Error: {auth.error.message}</div>;
  }

  return (
    <Suspense
      fallback={
        <Flex
          style={{
            justifyContent: "center",
            alignItems: "center",
            height: "80vh",
          }}
        >
          <img src={LoadingSvg} height={200} width={200} alt="LoadingSvg" />
        </Flex>
      }
    >
      {auth.isAuthenticated ? (
        <MainLayout auth={auth}>
          <AppRoutes />
        </MainLayout>
      ) : (
        <AuthLayout>
          <AppRoutes />
        </AuthLayout>
      )}
    </Suspense>
  );
}

export default App;
