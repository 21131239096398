export const theme = {
  token: {
    colorPrimary: "#1DB3B3",
    fontFamily: "Poppins,sans-serif",
    // colorInfo: "#1890FF",
    // colorError: "#FF4D4F",
    // colorSuccess: "#409D12",
    // colorWarning: "#FAAD14",
    // colorTextBase: "#264653",
  },
  components: {
    // Button: { borderRadius: 8, controlHeight: 32, controlHeightLG: 48, controlHeightSM: 24, controlPaddingHorizontal: 16 },
    // Input: { controlHeight: 48, controlHeightLG: 56, controlHeightSM: 38, controlOutline: "blue" },
    // Typography: { colorTextHeading: "#14142B" },
    Collapse: {
      /* here is your component tokens */
      contentPadding: "0px",
      headerPadding: "0px",
      fontSizeIcon: "12px",
      colorText: "#1F1F1F",
      colorIcon: "#1DB3B3",
    },
  },
};
