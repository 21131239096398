import {
  Col,
  Divider,
  Flex,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Typography,
} from "antd";
import React, { useEffect } from "react";
import { EditModelStyle } from "../../../assets/css/Home";
import { SettingIcon } from "../../../assets/images/Icons";
import FormButton from "../../Common/FormButton/FormButton";
import { CustomModel } from "../../Common/CustomModel/CustomModel";

/**
 * EditModel component: A modal window with a form to edit data.
 *
 * @param {Object} props
 * @param {Boolean} props.isShowEditModal - Whether to show the modal.
 * @param {Function} props.setIsShowEditModal - Function to toggle the modal visibility.
 * @param {Function} props.handleOnSubmit - Function to call when the form is submitted.
 * @param {Object} props.initialData - Initial data to populate the form fields.
 * @param {Object} props.form - Form instance to manage the form state.
 * @param {Boolean} props.isLoading - Whether the form is being submitted.
 */
export const EditModel = ({
  isShowEditModal,
  setIsShowEditModal,
  handleOnSubmit,
  initialData = {},
  form,
  isLoading = false,
}) => {
  /**
   * Set the initial form values when the component mounts.
   */
  useEffect(() => {
    if (form && initialData) {
      form.setFieldsValue(initialData);
    }
  }, [form, initialData]);

  return (
    <CustomModel
      title={"Edit Window Details"}
      onOpen={isShowEditModal}
      onCancel={() => setIsShowEditModal(false)}
    >
      <div>
        <Divider style={EditModelStyle.DividerBottomMargin} />
        <Form
          form={form}
          layout="vertical"
          onFinish={handleOnSubmit}
          initialValues={initialData}
        >
          <Row gutter={15}>
            <Col xl={24} xs={24} md={24} lg={24}>
              <Form.Item
                name="address"
                rules={[{ required: false, message: "Required Field" }]}
                label="Address"
              >
                <Input size="large" placeholder="Enter your address" />
              </Form.Item>
            </Col>
          </Row>
          <Divider style={EditModelStyle.Divider} />
          <Typography style={EditModelStyle.TypographyTitle}>Equity</Typography>
          <Row gutter={15}>
            <Col xl={12} xs={24} md={12} lg={12}>
              <Form.Item
                label="Percentage"
                name="EquityRatio"
                rules={[
                  {
                    required: false,
                    message: "Required Field",
                  },
                ]}
              >
                <InputNumber
                  style={{ width: "100%" }}
                  size="large"
                  min={0}
                  prefix={
                    <Typography style={EditModelStyle.InputNumberPrefix}>
                      %
                    </Typography>
                  }
                />
              </Form.Item>
            </Col>
            <Col xl={12} xs={24} md={12} lg={12}>
              <Form.Item
                label="Amount"
                name="InterestRate"
                rules={[
                  {
                    required: false,
                    message: "Required Field",
                  },
                ]}
              >
                <InputNumber
                  style={{ width: "100%" }}
                  size="large"
                  min={0}
                  prefix={
                    <Typography style={EditModelStyle.InputNumberPrefix}>
                      $
                    </Typography>
                  }
                />
              </Form.Item>
            </Col>
          </Row>
          <Flex style={EditModelStyle.FlexAlignCenter}>
            <Typography style={EditModelStyle.TypographyTitle}>
              Configure Interest Rate
            </Typography>
            <Flex style={EditModelStyle.SettingIconWrapper}>
              <SettingIcon />
            </Flex>
          </Flex>
          <Divider style={EditModelStyle.DividerBottomMargin} />
          <Typography style={EditModelStyle.TypographyTitle}>
            Calculated KPIs
          </Typography>
          <Row gutter={15}>
            <Col xl={8} xs={8} md={8} lg={8}>
              <Form.Item
                label="Purchasing Cost"
                name="PurchasingCost"
                rules={[
                  {
                    required: false,
                    message: "Required Field",
                  },
                ]}
              >
                <InputNumber
                  style={{ width: "100%" }}
                  size="large"
                  prefix={
                    <Typography style={EditModelStyle.InputNumberPrefix}>
                      %
                    </Typography>
                  }
                />
              </Form.Item>
            </Col>
            <Col xl={8} xs={8} md={8} lg={8}>
              <Form.Item
                label="Financing Cost"
                name="FinancingCost"
                rules={[
                  {
                    required: false,
                    message: "Required Field",
                  },
                ]}
              >
                <InputNumber
                  style={{ width: "100%" }}
                  size="large"
                  prefix={
                    <Typography style={EditModelStyle.InputNumberPrefix}>
                      $
                    </Typography>
                  }
                />
              </Form.Item>
            </Col>
            <Col xl={8} xs={8} md={8} lg={8}>
              <Form.Item
                label="Market Data"
                name="MarketData"
                rules={[
                  {
                    required: false,
                    message: "Required Field",
                  },
                ]}
              >
                <InputNumber
                  style={{ width: "100%" }}
                  size="large"
                  prefix={
                    <Typography style={EditModelStyle.InputNumberPrefix}>
                      $
                    </Typography>
                  }
                />
              </Form.Item>
            </Col>
          </Row>
          <Divider style={EditModelStyle.DividerBottomMargin} />
          <Flex style={EditModelStyle.ModalFooter}>
            <FormButton
              type="default"
              onClick={() => setIsShowEditModal(false)}
              htmlType="button"
              block={true}
            >
              Cancel
            </FormButton>
            <FormButton htmlType="submit" block={true} loading={isLoading}>
              Save
            </FormButton>
          </Flex>
        </Form>
      </div>
    </CustomModel>
  );
};
