export const ComparePropertyStyles = {
  Container: {
    width: "100%",
    marginBottom: "20px",
  },
  ImageContainer: {
    borderRadius: "12px",
    width: "100%",
    backgroundImage: "url(./compare-property.jpeg)",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  },
  Overlay: {
    height: "100%",
    alignItems: "flex-start",
    justifyContent: "flex-end",
    padding: "40px 40px 17px 40px",
    borderRadius: "12px",
    background:
      "linear-gradient(to right, rgb(54 51 51 / 64%), rgb(54 51 51 / 64%))",
  },
  TitleText: {
    color: "#FFFFFF",
    fontSize: "30px",
    fontWeight: "600",
    marginBottom: "8px",
  },
  SubTitleText: {
    color: "#FFFFFF",
    fontSize: "16px",
    width: "60%",
  },
  FlexWithMarginTop: {
    marginTop: "20px",
    width: "100%",
  },
  FlexCenterWithGap: {
    alignItems: "center",
    justifyContent: "center",
    gap: "10px",
  },
  CustomizeButtonStyle: {
    gap: "10px",
    paddingLeft: "20px",
    paddingRight: "20px",
    backgroundColor: "#f0f0f0",
    borderRadius: "4px",
  },
  ComparisonSection: {
    alignItems: "center",
    justifyContent: "center",
    gap: "10px",
    marginTop: "30px",
  },
};
