import { Flex } from "antd";
import React from "react";

const Settings = () => {
  return (
    <Flex
      vertical
      style={{ width: "100%", height: "20vh", paddingBottom: "40px" }}
    >
      Settings Page
    </Flex>
  );
};

export default Settings;
